@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}
